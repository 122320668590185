import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  InternalLink,
  FishermanIcon,
  FadeReveal,
  SlideReveal,
  multipleComponentIterator,
  HoverContainer,
  MultipleComponentIterator,
  FormattedDate,
  BlogPostLink,
  TeamMemberV2,
} from "@bluefin/components";
import { Grid, Header, Button, List, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-hero-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <Header as={"h1"}>
                <span
                  className={"no-highlight"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_header",
                    defaultValue: "About",
                  })}
                />
                <span
                  className={"highlight"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_header_highlighted",
                    defaultValue: "",
                  })}
                />
              </Header>
              <MarkdownContent
                className={"about-header-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "about_header_description",
                })}
              />
              <div className={"hero-action-buttons"}>
                <Button
                  id={"navigate-down-arrow"}
                  className={"navigate-down-arrow"}
                  to={"#navigate-down-arrow"}
                  as={InternalLink}
                  icon={true}
                  aria-label={"Scroll Down to Next Section"}
                >
                  <FishermanIcon iconName={"arrow-down"} />
                </Button>
              </div>
            </Grid.Column>
          </Grid>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={12}
              textAlign={"left"}
              className={"markdown-column"}
            >
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  className={"about-description"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"featured-stylists-list-container"}>
            <Grid.Column width={16}>
              <Header
                as={"h2"}
                className={"main-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_header",
                  defaultValue: "OUR TOP STYLISTS",
                })}
              />
              <MarkdownContent
                className={"about-staff-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_description",
                })}
              />
              <List>
                <SlideReveal
                  cascade={true}
                  triggerOnce={true}
                  direction={"up"}
                  fade={true}
                  children={multipleComponentIterator({
                    iterator: allFishermanBusinessTeam.nodes,
                    limit: 4,
                    components: [
                      {
                        component: (
                          <HoverContainer
                            displayOnTouchScreen={false}
                            forceDisplay={true}
                          />
                        ),
                        propMap: { key: "_id" },
                        children: [
                          {
                            component: (
                              <List.Item
                                className={"featured-stylist-item"}
                                event={{
                                  category: "Team",
                                  action: "View Team Member",
                                }}
                                as={InternalLink}
                              />
                            ),
                            propMap: {
                              to: createTeamMemberSlug,
                              "event.label": "name",
                            },
                            children: [
                              {
                                component: (
                                  <Grid
                                    className={"item-internal"}
                                    stackable={true}
                                  />
                                ),
                                children: [
                                  {
                                    component: (
                                      <Grid.Column
                                        width={10}
                                        textAlign={"left"}
                                        className={"staff-name-column"}
                                      />
                                    ),
                                    children: [
                                      {
                                        component: (
                                          <div className={"staff-name"} />
                                        ),
                                        propMap: { children: "name" },
                                      },
                                      {
                                        component: (
                                          <FishermanIcon iconName={"plus"} />
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    component: (
                                      <Grid.Column
                                        width={6}
                                        className={"staff-role-column"}
                                      />
                                    ),
                                    children: [
                                      {
                                        component: (
                                          <span
                                            className={"staff-role-and-social"}
                                          />
                                        ),
                                        children: [
                                          {
                                            component: (
                                              <span className={"staff-role"} />
                                            ),
                                            propMap: { children: "role" },
                                          },
                                          {
                                            component: (
                                              <span
                                                className={"staff-social"}
                                              />
                                            ),
                                            children: [
                                              {
                                                component: (
                                                  <HoverContainer.Content />
                                                ),
                                                children: [
                                                  {
                                                    component: (
                                                      <TeamMemberV2.Social
                                                        linked={false}
                                                      />
                                                    ),
                                                    propMap: {
                                                      social: "socialMedia",
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  })}
                />
              </List>
              <Button
                to={"/team/"}
                primary={true}
                as={InternalLink}
                event={{ category: "Team", action: "View All Team Members" }}
              >
                See all Stylists
              </Button>
            </Grid.Column>
          </Grid>
          <div className={"about-blog-container"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_header",
                defaultValue: "",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_description",
              })}
            />
            <Card.Group
              className={"featured-blogs"}
              itemsPerRow={2}
              stackable={true}
            >
              <MultipleComponentIterator
                iterator={allFishermanBusinessBlogPost.nodes}
                limit={2}
                components={[
                  {
                    component: <Card />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <Card.Content />,
                        children: [
                          {
                            component: (
                              <div className={"see-our-blog-container"} />
                            ),
                            children: [
                              {
                                component: (
                                  <Button
                                    to={"/blog/"}
                                    as={InternalLink}
                                    event={{
                                      category: "Blog",
                                      action: "View All Blog Posts",
                                    }}
                                    content={"Blog"}
                                  />
                                ),
                              },
                            ],
                          },
                          {
                            component: <Card.Header className={"title"} />,
                            propMap: { content: "title" },
                          },
                          {
                            component: <FormattedDate outputFormat={"PPP"} />,
                            propMap: { date: "publishedAt" },
                          },
                          {
                            component: (
                              <MarkdownContent className={"summary"} />
                            ),
                            propMap: { content: "summary" },
                          },
                          {
                            component: (
                              <BlogPostLink
                                content={
                                  <div>
                                    <span>READ</span>
                                    <FishermanIcon iconName={"arrow-right"} />
                                  </div>
                                }
                              />
                            ),
                            propMap: {
                              key: "_id",
                              slug: "slug",
                              title: "title",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessBlogPost(
      filter: { destination: { eq: "BLOG" }, status: { eq: 1 } }
    ) {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
